<template>
  <div class="edit-profile-container">
    <error :errors="errors" />
    <b-overlay :show="busy" rounded="sm">
    <portlet :title="$t('PAGES.PROFILE.RESET_PASSWORD.title')" foot-class="d-flex gap-10">
      <template slot="body">
          <b-form-group :state="validateState('old_password')" aria-describedby="oldPassword-live-feedback" id="fieldset-1" :label="$t('PAGES.PROFILE.RESET_PASSWORD.LABEL.old_password')" label-for="oldPassword">
            <b-form-input type="password" :state="validateState('old_password')" :placeholder="$t('PAGES.PROFILE.RESET_PASSWORD.PLACEHOLDER.old_password')" v-model.trim="old_password" id="oldPassword" trim></b-form-input>
            <b-form-invalid-feedback id="oldPassword-live-feedback">
              {{ $t('PAGES.PROFILE.RESET_PASSWORD.FEEDBACK.old_password') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="fieldset-2" :state="validateState('password')" aria-describedby="password-live-feedback" :label="$t('PAGES.PROFILE.RESET_PASSWORD.LABEL.password')" label-for="password">
                <b-form-input type="password" :state="validateState('password')" :placeholder="$t('PAGES.PROFILE.RESET_PASSWORD.PLACEHOLDER.password')" v-model="password" id="password" trim></b-form-input>
                <b-form-invalid-feedback id="password-live-feedback">
                  {{ $t('PAGES.PROFILE.RESET_PASSWORD.FEEDBACK.password') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group :state="validateState('password_confirmation')" aria-describedby="oldPassword-live-feedback" id="fieldset-3" :label="$t('PAGES.PROFILE.RESET_PASSWORD.LABEL.password_confirmation')" label-for="confirmPassword">
                <b-form-input type="password" :state="validateState('password_confirmation')" :placeholder="$t('PAGES.PROFILE.RESET_PASSWORD.PLACEHOLDER.password_confirmation')" v-model="password_confirmation" id="confirmPassword" trim></b-form-input>
                <b-form-invalid-feedback id="confirmPassword-live-feedback">
                  {{ $t('PAGES.PROFILE.RESET_PASSWORD.FEEDBACK.password_confirmation') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="onSubmit" variant="primary">
          {{ $t('PAGES.PROFILE.RESET_PASSWORD.BUTTON.submit') }}
        </b-button>
        <b-button class="ml-2" type="reset" variant="danger">
          {{ $t('PAGES.PROFILE.RESET_PASSWORD.BUTTON.cancel') }}
        </b-button>
      </template>
    </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import AvatarUploader from "../../../partials/layout/AvatarUploader";
  import Error from "../../../partials/widgets/Error";
  import { mapState } from "vuex";
  import { validationMixin } from "vuelidate";
  import { minLength, required, sameAs } from "vuelidate/lib/validators";
  import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
  import { CHANGE_PASSWORD } from "@/store/modules/auth.module";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    mixins: [validationMixin, sweetAlertMixin],
    validations: {
      password: { required, minLength: minLength(6) },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
      old_password: { required }
    },
    components: { Portlet, AvatarUploader, Error },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t('PAGES.PROFILE.RESET_PASSWORD.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: "/profile" },
        { title: this.$t('PAGES.PROFILE.RESET_PASSWORD.BREAD_CRUMBS_TITLES.CURRENT_TITLE') }
      ]);
    },
    /*computed: {
      ...mapState({
        errors: state => state.auth.errors
      })
    },*/
    data(){
      return {
        errors: null,
        old_password: '',
        password: '',
        password_confirmation: '',
        busy: false
      };
    },
    methods: {
      onSubmit(){
        this.$v.$touch();
        if (this.$v.$anyError) {
          this.warning({message: this.$t('PAGES.PROFILE.RESET_PASSWORD.TIPS.VALIDATION_TIPS')});
          return;
        }
        this.submitState();
        let password = this.password;
        let old_password = this.old_password;
        let password_confirmation = this.password_confirmation;
        const payload = {password, old_password, password_confirmation};
        this.busy = true;
        this.$store.dispatch(CHANGE_PASSWORD, payload)
          .then(()=> {
            this.busy = false;
            this.submitState(false);
            this.success({message: this.$t('PAGES.PROFILE.RESET_PASSWORD.TIPS.EDIT_SUCCESS')})
              .then(()=>{
                this.$router.replace({name: 'login'});
              })
          })
          .catch(()=> {this.busy = false; this.submitState(false)})
      },
      submitState(action = true){
        let submitBtn = document.getElementById("submit");
        if ( action ){
          submitBtn.classList.add(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
        }else {
          submitBtn.classList.remove(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
        }
      },
      validateState(name) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      },
    }
  }
</script>

<style scoped>

</style>
